import type { SWRResponse } from "swr"
import { User } from "types/apiTypes"
import { fetchApiData } from "./useApiData"
import useRegionData from "./useRegionData"

interface UserSWRResponse extends SWRResponse {
  data: User | undefined
}

const useUser = () => {
  const {
    currentRegion: { languageCode }
  } = useRegionData()

  const {
    data: user,
    isLoading,
    isValidating,
    mutate
  } = fetchApiData(
    `accounts/user/?language_code=${languageCode}`
  ) as UserSWRResponse

  return {
    isLoggedIn: !!user && !user.isAnonymous && !user.isGuest,
    isAccountHolder: !!user && !user.isAnonymous,
    isGuest: !!user && user.isGuest,
    isAnonymous: !!user && user.isAnonymous,
    user,
    isValidating,
    isLoading,
    mutate
  }
}

export default useUser
