import { TypeLink } from "types/contentful"
import { CURRENT_REGION } from "lib/environment"
import { BillingAddress, ShippingAddress } from "types/apiTypes"

export { default as cx } from "clsx"

export const track = (
  event: string,
  properties?: { [key: string]: string | number | null | undefined }
) => {
  window?.analytics?.track?.(event, properties ?? {})
}

export const getLastPath = (pathname: string) => {
  const regex = /.*\/([^/.]+)\/?/
  const found = pathname.match(regex)
  const segment = found ? found[1] : ``
  return segment
}

export const checkIsMagazineCategory = (
  pathname: string,
  categoryItems: TypeLink[]
) => {
  const categories = categoryItems.map(item => item?.title?.toLowerCase())
  const segment = getLastPath(pathname)
  return categories.includes(segment)
}

export const copyText = async (text: string) => {
  if (!navigator?.clipboard) {
    return false
  }
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (error) {
    return false
  }
}

export const formatCurrency = ({
  symbol,
  value,
  showFreeForZero = true,
  showUSPrefixInGlobal = false,
  showDecimals = false
}: {
  symbol: string | undefined
  value: string | number
  showFreeForZero?: boolean
  showUSPrefixInGlobal?: boolean
  showDecimals?: boolean
}) => {
  if ((value === 0 || value === `0.00`) && showFreeForZero) return `Free`

  const prefix =
    showUSPrefixInGlobal && CURRENT_REGION === `global` && symbol === `$`
      ? `US`
      : ``
  let sanitizedValue = typeof value === `number` ? value : Number(value)
  if (Number.isNaN(sanitizedValue)) sanitizedValue = 0

  return showDecimals
    ? `${prefix}${symbol}${sanitizedValue.toFixed(2)}`
    : `${prefix}${symbol}${sanitizedValue.toFixed(2).replace(`.00`, ``)}`
}

export const getAddressStateName = (
  state: ShippingAddress[`state`] | BillingAddress[`state`]
): string | undefined => (state.name.length ? state.name : state.unknown)
