import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import tw, { css } from "twin.macro"

const containerStyles = [
  tw`bg-white px-5 py-4`,
  css`
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.3);

    max-width: 100vw;
    width: 100vw;
    margin: 0 -20px -20px -20px;

    @media (min-width: 768px) {
      margin: 0;
      max-width: 100%;
      width: 28rem;
      margin: 0;
    }
  `
]

const ToastNotification = forwardRef(({ children, testId, ...props }, ref) => (
  <div
    css={containerStyles}
    role="alert"
    aria-live="assertive"
    ref={ref}
    data-testid={testId}
    {...props}
  >
    {children}
  </div>
))

ToastNotification.defaultProps = {
  testId: undefined
}

ToastNotification.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  testId: PropTypes.string
}

export default ToastNotification
