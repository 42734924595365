import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import ToastNotification from "components/Toast/ToastNotification"
import { GreenCheckIcon } from "components/Icons"

const GreenCheckBasicNotification = forwardRef(({ message, testId }, ref) => (
  <ToastNotification
    ref={ref}
    testId={testId}
    className="flex items-center gap-4"
  >
    <GreenCheckIcon width={28} height={28} />
    {message}
  </ToastNotification>
))

GreenCheckBasicNotification.defaultProps = {
  testId: undefined
}

GreenCheckBasicNotification.propTypes = {
  message: PropTypes.string.isRequired,
  testId: PropTypes.string
}

export default GreenCheckBasicNotification
