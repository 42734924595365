import React, { memo } from "react"
import { useLocation } from "@gatsbyjs/reach-router"

import type { CardProps } from "components/FollowedBrandsPage/Card"
import useFollowingBrands from "hooks/useFollowingBrands"

interface UndoButtonProps {
  id: CardProps[`id`]
}

const UndoButton = memo(({ id }: UndoButtonProps) => {
  const location = useLocation()

  const returnPage = () => {
    if (!location?.pathname.includes(`/my-account/followed-brands/`))
      return undefined

    const searchParams = location?.search

    if (!searchParams || searchParams === ``) return 1
    const pageNumber = new URLSearchParams(searchParams).get(`p`)

    return pageNumber ? parseInt(pageNumber, 10) : 1
  }

  const { follow } = useFollowingBrands({ id, brandPage: returnPage() })

  return (
    <button className="font-medium underline" onClick={follow} type="button">
      Undo
    </button>
  )
})

export default UndoButton
