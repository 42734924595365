import * as Sentry from "@sentry/gatsby"
import {
  isStaging,
  isPreviewDeck,
  isLocal,
  isProduction,
  sentryDsn,
  RELEASE_NAME
} from "lib/environment"

const environment = () => {
  if (isStaging() || isPreviewDeck()) {
    return `development`
  }
  if (isProduction()) {
    return `production`
  }
  if (isLocal()) {
    return `localhost`
  }
  return undefined
}

const config = {
  dsn: isStaging() || isPreviewDeck() || isProduction() ? sentryDsn : null,
  environment: environment(),
  release: RELEASE_NAME,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: isProduction() ? 0.01 : 0,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration()
  ],
  tracesSampleRate: isProduction() ? 0.01 : 0,
  beforeSend(event) {
    if (window) {
      const isFacebookBrowser =
        window?.navigator && navigator?.userAgent?.includes?.(`FBAN`)

      const isInstagramBrowser =
        window?.navigator &&
        window?.navigator?.userAgent?.includes?.(`Instagram`)

      const isHeadlessChromeBrowser =
        window?.navigator && navigator?.userAgent?.includes?.(`HeadlessChrome`)

      if (isFacebookBrowser) return null
      if (isInstagramBrowser) return null
      if (isHeadlessChromeBrowser) return null
    }

    const title = event?.exception?.values?.[0]?.value || ``

    const stacktrace = event?.exception?.values?.[0]?.stacktrace?.frames || []

    const isOriginClarity =
      stacktrace.find(
        frame =>
          frame.abs_path?.includes(`clarity`) ||
          frame.abs_path?.includes(`afterpay`) ||
          frame.filename?.includes(`clarity`) ||
          frame.filename?.includes(`afterpay`)
      ) !== undefined

    const isOriginDynamicYield =
      stacktrace.find(frame => frame.filename?.includes(`dynamic`)) !==
      undefined

    if (
      title.match(/We couldn't load "\/(.*?)\/page-data\/sq\/d\/(.*?)\.json"/)
    ) {
      return null
    }

    if (title.match(/Failed to fetch/i)) {
      return null
    }

    if (
      title.match(
        /This is a mock error for Sentry that should be blocked no.2/i
      )
    ) {
      return null
    }

    if (title.match(/Loading chunk [A-Za-z0-9]+ failed\./i)) {
      return null
    }

    if (isOriginClarity) {
      return null
    }

    // DY timeout error
    // Ref: https://wolfandbadgercom.sentry.io/issues/5739116181/?project=5525219&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&sort=date&statsPeriod=30d&stream_index=0
    if (isOriginDynamicYield && /timeout/i.test(title)) {
      return null
    }

    return event
  }
}

export default config
