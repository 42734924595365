import React from "react"
import WishListContext from "contexts/WishList"
import useWishListContextData from "contexts/WishList/useWishListContextData"

interface ProviderProps {
  children: JSX.Element
}

const WishListProvider = ({ children }: ProviderProps) => {
  const data = useWishListContextData()

  return (
    <WishListContext.Provider value={data}>{children}</WishListContext.Provider>
  )
}

export default WishListProvider
