import React from "react"
import OneTrustContext from "contexts/OneTrust"
import useOneTrustData from "contexts/OneTrust/useOneTrustData"

interface ProviderProps {
  children: JSX.Element
}

const OneTrustProvider = ({ children }: ProviderProps) => {
  const data = useOneTrustData()

  return (
    <OneTrustContext.Provider value={data}>{children}</OneTrustContext.Provider>
  )
}

export default OneTrustProvider
