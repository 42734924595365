import { createContext } from "react"

export interface WishListSlimItem {
  productId: number
  wishId: number
}

export interface WishListItem {
  id: number
  isOwner: boolean
  product: {
    active: boolean
    altImagePicture: string
    combinedInStock: boolean
    combinedItemsAvailable: number
    designer: {
      id: number
      name: string
      url: string
    }
    discountedPrice: number
    discountedPriceDisplay: string
    id: number
    isFulfillable: boolean
    mainImagePicture: string
    name: string
    normalPrice: number
    normalPriceDisplay: string
    parentName: string
    slug: string
    url: string
    uri: string
    variantName: string
    size: string
  }
}

export interface WishListSizeItem {
  id: number
  key: string
  price: number
  salePrice: number
  sizeId: string
  stock: number
  title: string
}

interface Response {
  success: boolean
  error?: string
}

export interface WishListContextType {
  wishListSlim: WishListSlimItem[]
  totalWishListItems: number
  isInWishList: (id: number, sizeId?: number) => boolean
  add: (
    id: number,
    productIdToRestoreScroll?: number,
    isDynamicYieldProduct?: boolean
  ) => Promise<Response>
  remove: (
    id: number,
    sizeId?: number,
    suppressToasts?: boolean
  ) => Promise<Response>
  getWishListItem: (id: number) => WishListSlimItem | undefined
}

const WishListContext = createContext<WishListContextType>({
  wishListSlim: [],
  totalWishListItems: 0,
  isInWishList: () => false,
  add: async () => ({ success: false }),
  remove: async () => ({ success: false }),
  getWishListItem: () => undefined
})

export default WishListContext
