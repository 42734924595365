import React from "react"
import type { GatsbyBrowser } from "gatsby"
import ResizeObserver from "resize-observer-polyfill"
import * as Sentry from "@sentry/gatsby"
import sentryConfig from "./sentry-config-browser"
import WishListProvider from "./src/contexts/WishList/Provider"
import ToastProvider from "./src/components/Toast/ToastProvider"
import OneTrustProvider from "./src/contexts/OneTrust/Provider"

Sentry.init(sentryConfig)

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OneTrust: any
  }
}

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

// Using wrapPageElement rather than wrapRootElement to provide access to the useLocation hook
// https://github.com/gatsbyjs/gatsby/issues/26963#issuecomment-696037008
// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element
}) => (
  <ToastProvider>
    <OneTrustProvider>
      <WishListProvider>{element}</WishListProvider>
    </OneTrustProvider>
  </ToastProvider>
)

// OneTrust has issues with SPAs and needs to be reloaded on route change in order to correctly display the content. This function comes from their documentation:
// https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US
function reloadOTBanner() {
  const otConsentSdk = document.getElementById(`onetrust-consent-sdk`)
  if (otConsentSdk) {
    otConsentSdk.remove()
  }

  if (window.OneTrust != null) {
    window.OneTrust.Init()

    setTimeout(() => {
      window.OneTrust.LoadBanner()

      const toggleDisplay =
        document.getElementsByClassName(`ot-sdk-show-settings`)

      for (let i = 0; i < toggleDisplay.length; i += 1) {
        const element = toggleDisplay[i] as HTMLButtonElement
        element.onclick = event => {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }
    }, 1000)
  }
}

export const onPreRouteUpdate = () => {
  reloadOTBanner()
}
