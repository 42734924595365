import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import ToastNotification from "../ToastNotification"

const BasicNotification = forwardRef(({ message, testId }, ref) => (
  <ToastNotification ref={ref} testId={testId}>
    {message}
  </ToastNotification>
))

BasicNotification.defaultProps = {
  testId: undefined
}

BasicNotification.propTypes = {
  message: PropTypes.string.isRequired,
  testId: PropTypes.string
}

export default BasicNotification
