exports.components = {
  "component---src-components-category-page-index-js": () => import("./../../../src/components/CategoryPage/index.js" /* webpackChunkName: "component---src-components-category-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-apply-invited-js": () => import("./../../../src/pages/apply-invited.js" /* webpackChunkName: "component---src-pages-apply-invited-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-apply-referred-js": () => import("./../../../src/pages/apply-referred.js" /* webpackChunkName: "component---src-pages-apply-referred-js" */),
  "component---src-pages-checkout-v-2-success-tsx": () => import("./../../../src/pages/checkout/v2/success.tsx" /* webpackChunkName: "component---src-pages-checkout-v-2-success-tsx" */),
  "component---src-pages-checkout-v-2-tsx": () => import("./../../../src/pages/checkout/v2/[...].tsx" /* webpackChunkName: "component---src-pages-checkout-v-2-tsx" */),
  "component---src-pages-designers-new-js": () => import("./../../../src/pages/designers/new.js" /* webpackChunkName: "component---src-pages-designers-new-js" */),
  "component---src-pages-designers-slug-js": () => import("./../../../src/pages/designers/[...slug].js" /* webpackChunkName: "component---src-pages-designers-slug-js" */),
  "component---src-pages-edits-index-tsx": () => import("./../../../src/pages/edits/index.tsx" /* webpackChunkName: "component---src-pages-edits-index-tsx" */),
  "component---src-pages-edits-slug-js": () => import("./../../../src/pages/edits/[...slug].js" /* webpackChunkName: "component---src-pages-edits-slug-js" */),
  "component---src-pages-features-slug-js": () => import("./../../../src/pages/features/[...slug].js" /* webpackChunkName: "component---src-pages-features-slug-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-category-slug-slug-tsx": () => import("./../../../src/pages/magazine/[categorySlug]/[...slug].tsx" /* webpackChunkName: "component---src-pages-magazine-category-slug-slug-tsx" */),
  "component---src-pages-magazine-tsx": () => import("./../../../src/pages/magazine.tsx" /* webpackChunkName: "component---src-pages-magazine-tsx" */),
  "component---src-pages-meta-checkout-redirect-tsx": () => import("./../../../src/pages/meta-checkout-redirect.tsx" /* webpackChunkName: "component---src-pages-meta-checkout-redirect-tsx" */),
  "component---src-pages-my-account-address-book-js": () => import("./../../../src/pages/my-account/address-book.js" /* webpackChunkName: "component---src-pages-my-account-address-book-js" */),
  "component---src-pages-my-account-contact-preferences-js": () => import("./../../../src/pages/my-account/contact-preferences.js" /* webpackChunkName: "component---src-pages-my-account-contact-preferences-js" */),
  "component---src-pages-my-account-credit-tsx": () => import("./../../../src/pages/my-account/credit.tsx" /* webpackChunkName: "component---src-pages-my-account-credit-tsx" */),
  "component---src-pages-my-account-followed-brands-tsx": () => import("./../../../src/pages/my-account/followed-brands.tsx" /* webpackChunkName: "component---src-pages-my-account-followed-brands-tsx" */),
  "component---src-pages-my-account-index-tsx": () => import("./../../../src/pages/my-account/index.tsx" /* webpackChunkName: "component---src-pages-my-account-index-tsx" */),
  "component---src-pages-my-account-orders-index-js": () => import("./../../../src/pages/my-account/orders/index.js" /* webpackChunkName: "component---src-pages-my-account-orders-index-js" */),
  "component---src-pages-my-account-password-js": () => import("./../../../src/pages/my-account/password.js" /* webpackChunkName: "component---src-pages-my-account-password-js" */),
  "component---src-pages-my-account-personal-info-js": () => import("./../../../src/pages/my-account/personal-info.js" /* webpackChunkName: "component---src-pages-my-account-personal-info-js" */),
  "component---src-pages-my-account-social-accounts-js": () => import("./../../../src/pages/my-account/social-accounts.js" /* webpackChunkName: "component---src-pages-my-account-social-accounts-js" */),
  "component---src-pages-my-account-wishlist-tsx": () => import("./../../../src/pages/my-account/wishlist.tsx" /* webpackChunkName: "component---src-pages-my-account-wishlist-tsx" */),
  "component---src-pages-pages-apply-tsx": () => import("./../../../src/pages/pages/apply.tsx" /* webpackChunkName: "component---src-pages-pages-apply-tsx" */),
  "component---src-pages-pages-competition-sign-up-tsx": () => import("./../../../src/pages/pages/competition-sign-up.tsx" /* webpackChunkName: "component---src-pages-pages-competition-sign-up-tsx" */),
  "component---src-pages-pages-event-sign-up-tsx": () => import("./../../../src/pages/pages/event-sign-up.tsx" /* webpackChunkName: "component---src-pages-pages-event-sign-up-tsx" */),
  "component---src-pages-pages-help-slug-slug-js": () => import("./../../../src/pages/pages/[helpSlug]/[...slug].js" /* webpackChunkName: "component---src-pages-pages-help-slug-slug-js" */),
  "component---src-pages-pages-stores-tsx": () => import("./../../../src/pages/pages/stores.tsx" /* webpackChunkName: "component---src-pages-pages-stores-tsx" */),
  "component---src-pages-preview-slug-tsx": () => import("./../../../src/pages/preview/[...slug].tsx" /* webpackChunkName: "component---src-pages-preview-slug-tsx" */),
  "component---src-pages-returns-v-2-index-tsx": () => import("./../../../src/pages/returns/v2/index.tsx" /* webpackChunkName: "component---src-pages-returns-v-2-index-tsx" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shopping-bag-js": () => import("./../../../src/pages/shopping-bag.js" /* webpackChunkName: "component---src-pages-shopping-bag-js" */),
  "component---src-pages-sign-in-checkout-tsx": () => import("./../../../src/pages/sign-in/checkout.tsx" /* webpackChunkName: "component---src-pages-sign-in-checkout-tsx" */),
  "component---src-pages-sign-in-create-account-js": () => import("./../../../src/pages/sign-in/create-account.js" /* webpackChunkName: "component---src-pages-sign-in-create-account-js" */),
  "component---src-pages-sign-in-index-tsx": () => import("./../../../src/pages/sign-in/index.tsx" /* webpackChunkName: "component---src-pages-sign-in-index-tsx" */),
  "component---src-pages-sign-in-new-password-js": () => import("./../../../src/pages/sign-in/new-password.js" /* webpackChunkName: "component---src-pages-sign-in-new-password-js" */),
  "component---src-pages-sign-in-reset-password-js": () => import("./../../../src/pages/sign-in/reset-password.js" /* webpackChunkName: "component---src-pages-sign-in-reset-password-js" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-tastemakers-js": () => import("./../../../src/pages/tastemakers.js" /* webpackChunkName: "component---src-pages-tastemakers-js" */),
  "component---src-pages-tastemakers-tastemaker-slug-slug-js": () => import("./../../../src/pages/tastemakers/[tastemakerSlug]/[...slug].js" /* webpackChunkName: "component---src-pages-tastemakers-tastemaker-slug-slug-js" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-afterpay-js": () => import("./../../../src/templates/afterpay.js" /* webpackChunkName: "component---src-templates-afterpay-js" */),
  "component---src-templates-designers-letter-js": () => import("./../../../src/templates/designersLetter.js" /* webpackChunkName: "component---src-templates-designers-letter-js" */),
  "component---src-templates-essential-worker-discount-js": () => import("./../../../src/templates/essentialWorkerDiscount.js" /* webpackChunkName: "component---src-templates-essential-worker-discount-js" */),
  "component---src-templates-order-detail-js": () => import("./../../../src/templates/orderDetail.js" /* webpackChunkName: "component---src-templates-order-detail-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-unidays-js": () => import("./../../../src/templates/unidays.js" /* webpackChunkName: "component---src-templates-unidays-js" */),
  "component---src-templates-youth-discount-js": () => import("./../../../src/templates/youthDiscount.js" /* webpackChunkName: "component---src-templates-youth-discount-js" */)
}

