import { useEffect, useCallback } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { scrollToElement } from "lib/helpers"

const useRestoreScrollPosition = ({ shouldNotScroll = false } = {}) => {
  const location = useLocation()

  const saveProductScrollPosition = useCallback(
    ({ productId }) => {
      window?.localStorage?.setItem(`productToScrollTo`, productId)
      window?.sessionStorage?.removeItem?.(
        `@@scroll|${location.pathname}|${location.key}`
      )
    },
    [location.key, location.pathname]
  )

  const restorePxScrollPosition = useCallback(() => {
    const prevPxScrollPosition = window?.sessionStorage?.getItem?.(
      `@@scroll|${location.pathname}|${location.key}`
    )

    if (prevPxScrollPosition) {
      window?.scrollTo?.(0, prevPxScrollPosition)
    }
  }, [location.key, location.pathname])

  const restoreProductScrollPosition = useCallback(() => {
    const productIdToScrollTo = window.localStorage.getItem(`productToScrollTo`)

    const elementToScrollTo = document.querySelector(
      `[data-product-id="${productIdToScrollTo && productIdToScrollTo}"]`
    )

    if (productIdToScrollTo && elementToScrollTo) {
      setTimeout(() => {
        scrollToElement({
          element: elementToScrollTo,
          offset: 100
        })
        window.localStorage.removeItem(`productToScrollTo`)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (shouldNotScroll) return

    const prevPxScrollPosition = window?.sessionStorage?.getItem?.(
      `@@scroll|${location.pathname}|${location.key}`
    )
    const productIdToScrollTo = window.localStorage.getItem(`productToScrollTo`)

    const isScrollingToPx = !!prevPxScrollPosition
    const isScrollingToProduct = !!productIdToScrollTo

    if (isScrollingToPx) {
      restorePxScrollPosition()
    }

    if (isScrollingToProduct) {
      restoreProductScrollPosition()
    }
  }, [
    location.key,
    location.pathname,
    restoreProductScrollPosition,
    restorePxScrollPosition,
    shouldNotScroll
  ])

  return {
    saveProductScrollPosition
  }
}

export default useRestoreScrollPosition
