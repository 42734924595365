import { useStaticQuery, graphql } from "gatsby"
import { CURRENT_REGION } from "../lib/environment"

const useRegionData = () => {
  const { allRegionsJson } = useStaticQuery(graphql`
    query allRegions {
      allRegionsJson {
        nodes {
          regionCode
          currencyCode
          currencySymbol
          zoneName
          languageCode
          phoneNumber
          timestamp
          headerText
          sizingLabel
          languageCode
          urlPrefix
          legacySymbol
          navItems {
            uri
            title
            type
            giftShopItems {
              title
              items {
                uri
                title
              }
            }
            discoverItems {
              title
              items {
                uri
                title
              }
            }
            newDesigners {
              title
              uri
            }
            banners {
              image
              title
              uri
            }
            featuredDesigners {
              image
              title
              uri
            }
            featuredDesignersList {
              image
              title
              uri
            }
            items {
              subnav {
                title
                uri
              }
            }
          }
          deliveryAndReturns
          defaultCollectionEdits {
            title
            image
            uri
          }
          recentlyAddedEdits {
            beauty {
              image
              title
              uri
            }
            home {
              image
              title
              uri
            }
            men {
              image
              title
              uri
            }
            women {
              image
              title
              uri
            }
          }
        }
      }
    }
  `)
  const allRegions = allRegionsJson.nodes
  const currentRegion = allRegions.filter(
    region => region.urlPrefix === CURRENT_REGION
  )[0]

  return { allRegions, currentRegion }
}

export default useRegionData
