import { fetchApiData } from "hooks/useApiData"
import useUser from "hooks/useUser"
import type { WishListSlimItem } from "contexts/WishList"

const TEN_MINUTES_IN_MS = 600000

const useFetchWishListSlim = () => {
  const { isLoggedIn } = useUser()

  const { data: dataWishListSlim }: { data: WishListSlimItem[] } = fetchApiData(
    isLoggedIn ? `wishlist/slim` : null,
    {
      focusThrottleInterval: TEN_MINUTES_IN_MS
    }
  )

  return { wishListSlim: dataWishListSlim ?? [] }
}

export default useFetchWishListSlim
