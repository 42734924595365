import { useCallback } from "react"
import * as Sentry from "@sentry/react"

import { canUseDOM } from "lib/helpers"
import type {
  DynamicYieldExperience,
  GroupsWithRecommendations,
  DynamicYieldProductRecommendationsExperience,
  TrackEngagementParams,
  GroupWithRecommendations,
  GroupsWithVariations,
  DynamicYieldVariationsExperience,
  DynamicYieldProductRecommendation,
  DynamicYieldProduct
} from "hooks/useDynamicYieldExperience/types"

const useDynamicYieldExperience = () => {
  // Campaigns in Dynamic Yield should have the component they are targeting in their groups list
  const buildGroupsWithRecommendations = useCallback(
    ({
      experiences,
      components
    }: {
      experiences?: DynamicYieldExperience[]
      components: string[]
    }) => {
      if (!experiences) return null

      const groupsWithRecommendations: GroupsWithRecommendations = {}
      // Only uses the first experience in the array - if there are multiple experiences matching the component this should be fixed in the Dynamic Yield dashboard and only one left live at a time.
      components.forEach(component => {
        const matchedExperience = experiences.find(
          experience =>
            experience.groups.includes(component) &&
            experience.type === `RECS_DECISION`
        ) as DynamicYieldProductRecommendationsExperience

        // Dynamic Yield should handle which variation to serve - there should only ever be one variation in the response array. If the variations array is empty, then the user is in the control group, and we still need to return the campaign ID and variant name to track the view.
        const recommendedVariations =
          matchedExperience?.variations?.[0]?.payload?.data ?? null

        groupsWithRecommendations[component] = {
          title: recommendedVariations?.custom?.title || null,
          subtitle: recommendedVariations?.custom?.subtitle || null,
          recommendations: recommendedVariations?.slots || null,
          campaignId: matchedExperience?.id || null,
          variantName: matchedExperience?.name || null,
          decisionId: matchedExperience?.decisionId || ``
        }
      })

      return groupsWithRecommendations
    },
    []
  )

  const buildGroupsWithVariations = useCallback(
    ({
      experiences,
      components
    }: {
      experiences?: DynamicYieldExperience[]
      components: string[]
    }) => {
      if (!experiences) return null

      const groupsWithVariations: GroupsWithVariations = {}
      // Only uses the first experience in the array - if there are multiple experiences matching the component this should be fixed in the Dynamic Yield dashboard and only one left live at a time.
      components.forEach(component => {
        const matchedExperience = experiences.find(
          experience =>
            experience.groups.includes(component) &&
            experience.type === `DECISION`
        ) as DynamicYieldVariationsExperience

        // Dynamic Yield should handle which variation to serve - there should only ever be one variation in the response array. If the variations array is empty, then the user is in the control group, and we still need to return the campaign ID and variant name to track the view.
        groupsWithVariations[component] =
          matchedExperience?.variations?.[0]?.payload?.data ?? null
      })

      return groupsWithVariations
    },
    []
  )

  const buildProductPropsFromRecommendations = useCallback(
    (
      dynamicYieldRecommendations: DynamicYieldProductRecommendation[]
    ): DynamicYieldProduct[] => {
      // Remove the domain from the URI to ensure the links point to the relevant site in each environment
      const removeProductionPrefix = (uri?: string) =>
        uri ? uri.replace(`https://www.wolfandbadger.com`, ``) : ``

      return dynamicYieldRecommendations.map(recommendation => {
        const { productData } = recommendation

        return {
          uri: removeProductionPrefix(productData?.url) || ``,
          price: productData?.price,
          // Data feed also contains the brand name and size in the product name, split by `|` - we only want the product name
          title: productData?.name?.split(`|`)[0] || ``,
          algolia_is_available: productData?.in_stock,
          mainImage: {
            image: productData?.image_url || ``,
            altText: `Product image for ${productData?.name}` || ``
          },
          brand: {
            title: productData?.brand || ``,
            uri:
              removeProductionPrefix(productData?.designer_profile) ||
              removeProductionPrefix(productData?.url) ||
              ``
          },
          // group_id should ensure all products with multiple sizes correctly trigger the selector when the wishlist button is clicked by the user
          id:
            parseInt(productData?.group_id, 10) ||
            parseInt(recommendation?.sku, 10) ||
            0,
          sku: productData?.group_id || recommendation?.sku || ``,
          // The actual original/sale price doesn't appear in the FeedOptimise feed - the price is the sale price
          salePrice: productData?.price,
          onSale: false,
          altImage:
            productData?.additional_image_link || productData?.image_url || ``,
          slotId: recommendation?.slotId
        }
      })
    },
    []
  )

  const sendEngagementEvent = async (engagement: {
    type?: string
    descisionId?: string
    slotId?: string
    productId?: number
  }) => {
    // Ensure that the event is still logged even if it fires before GTM has finished initialising
    window.dataLayer = window.dataLayer || []

    try {
      window?.dataLayer?.push({
        event: `Dynamic Yield Experience Engagement`,
        "Dynamic Yield Experience Engagement": engagement
      })
    } catch (error: unknown) {
      Sentry.captureException(error)
    }
  }

  const trackEngagement = useCallback(
    async ({ type, decisionId, slotId, productId }: TrackEngagementParams) => {
      if (!canUseDOM) return

      if (
        ((type === `IMP` || type === `CLICK`) && !decisionId) ||
        (type === `SLOT_CLICK` && !slotId) ||
        (type === `WISHLIST_ADD` && !productId)
      )
        return

      const engagement = {
        type,
        decisionId,
        slotId,
        productId
      }

      sendEngagementEvent(engagement)
    },
    []
  )

  return {
    buildGroupsWithRecommendations,
    buildGroupsWithVariations,
    buildProductPropsFromRecommendations,
    trackEngagement
  }
}

export default useDynamicYieldExperience

export {
  DynamicYieldExperience,
  GroupsWithRecommendations,
  DynamicYieldProductRecommendationsExperience,
  TrackEngagementParams,
  GroupWithRecommendations,
  GroupsWithVariations,
  DynamicYieldVariationsExperience,
  DynamicYieldProductRecommendation,
  DynamicYieldProduct
}
