import { useState } from "react"
import { OneTrustContextType } from "contexts/OneTrust"

const useOneTrustData = (): OneTrustContextType => {
  const [oneTrustAnalyticsApplied, setOneTrustAnalyticsApplied] =
    useState(false)

  return {
    oneTrustAnalyticsApplied,
    setOneTrustAnalyticsApplied
  }
}

export default useOneTrustData
