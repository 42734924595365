import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { navigate } from "gatsby"
import { sendApiData, mutateApiData } from "hooks/useApiData"
import useToast from "hooks/useToast"
import useUser from "hooks/useUser"
import type { WishListContextType } from "contexts/WishList"
import useFetchWishListSlim from "contexts/WishList/useFetchWishListSlim"
import { canUseDOM } from "lib/helpers"
import useRestoreScrollPosition from "hooks/useRestoreScrollPosition"
import type { WishProduct } from "types/apiTypes"
import useDynamicYieldExperience from "hooks/useDynamicYieldExperience"

declare global {
  interface Window {
    addToWishList?: (id: number) => Promise<boolean>
    removeFromWishList?: (id: number) => Promise<boolean>
    updateDYButtonStyles?: () => void
  }
}

const useWishListContextData = (): WishListContextType => {
  const [idToAddAfterLogin, setIdToAddAfterLogin] = useState<number | null>(
    null
  )
  const trackDYAddAfterLogin = useRef(false)
  const { enqueueToast } = useToast()
  const location = useLocation()
  const { isLoggedIn } = useUser()
  const { wishListSlim } = useFetchWishListSlim()
  const { saveProductScrollPosition } = useRestoreScrollPosition()
  const { trackEngagement } = useDynamicYieldExperience()

  const totalWishListItems = useMemo(
    () => wishListSlim?.length ?? 0,
    [wishListSlim]
  )

  const getWishListItem = useCallback(
    (id: number) => (wishListSlim ?? [])?.find(item => item.productId === id),
    [wishListSlim]
  )

  // Include sizeIds in instances where it's important that ANY variant of a product is in the wishlist
  const isInWishList = useCallback(
    (id: number, sizeId?: number) => {
      const wishListProductIds = new Set(
        wishListSlim.map(item => item.productId)
      )

      if (wishListProductIds.has(id)) {
        return true
      }

      if (sizeId) {
        if (wishListProductIds.has(sizeId)) {
          return true
        }
      }

      return false
    },
    [wishListSlim]
  )

  const sendAddRequest = useCallback(
    async (id: number) => {
      try {
        const addResponse = await sendApiData({
          endpoint: `wishlist/add-product/`,
          method: `POST`,
          body: {
            productId: id
          }
        })

        const responseJson: {
          id: number
          isOwner: boolean
          product: WishProduct
        } = await addResponse.json()

        await mutateApiData(`wishlist/slim`)

        mutateApiData(`wishlist?limit=20&page=1`)

        enqueueToast(`Item was added to your wishlist`, {
          variant: `wishlistAdd`,
          subtext: `${totalWishListItems + 1} items`,
          testId: `wishlist-toast-content`
        })

        window?.analytics?.track?.(`Product Added to Wishlist`, {
          wishlist_id: responseJson?.id || null,
          wishlist_name: responseJson?.product?.name || null,
          product_id: id,
          sku: id,
          category: null,
          name: responseJson?.product?.name || null,
          brand: responseJson?.product?.designer?.name || null,
          variant: responseJson?.product?.variantName || null,
          price: responseJson?.product?.normalPrice || null,
          quantity: 1,
          coupon: null,
          position: null,
          url: responseJson?.product?.url || null,
          image_url: responseJson?.product?.mainImagePicture || null
        })

        return { success: true }
      } catch (error) {
        const isErrorWithResponse = error instanceof Response

        if (isErrorWithResponse && error.status === 400) {
          const errorJson = await error.json()

          if (
            errorJson?.otherErrors &&
            errorJson?.otherErrors === `The product is already in the wishlist`
          ) {
            enqueueToast(`Item is already in your wishlist`, {
              testId: `wishlist-toast-content`
            })

            return {
              success: false,
              error: errorJson?.otherErrors
            }
          }
        }

        return { success: false }
      }
    },
    [enqueueToast, totalWishListItems]
  )

  const add = useCallback(
    async (
      id: number,
      productIdToRestoreScroll?: number,
      isDynamicYieldProduct?: boolean
    ) => {
      if (
        !id ||
        typeof id !== `number` ||
        (productIdToRestoreScroll &&
          typeof productIdToRestoreScroll !== `number`)
      )
        return { success: false, error: `Invalid parameters` }

      if (!isLoggedIn) {
        if (productIdToRestoreScroll) {
          saveProductScrollPosition({ productId: productIdToRestoreScroll })
        } else {
          const currentScrollPosition = window?.sessionStorage?.getItem?.(
            `@@scroll|${location.pathname}|${location.key}`
          )

          window.localStorage.setItem(
            `wishlistScrollPosition`,
            `${currentScrollPosition}`
          )
        }

        setIdToAddAfterLogin(id)

        if (isDynamicYieldProduct) {
          trackDYAddAfterLogin.current = true
        }

        navigate(`/sign-in`, {
          state: {
            // Use window over useLocation because the location hook doesn't correctly update the search param when moving between pages
            next: `${window?.location.pathname}${window?.location.search}`
          }
        })

        return { success: false, error: `User is not logged in` }
      }

      const hasAdded = await sendAddRequest(id)

      return hasAdded
    },
    [
      isLoggedIn,
      sendAddRequest,
      location.pathname,
      saveProductScrollPosition,
      location.key
    ]
  )

  const remove = useCallback(
    async (id: number, sizeId?: number, suppressToasts?: boolean) => {
      if (!id || typeof id !== `number`)
        return { success: false, error: `Invalid parameters` }

      let idToRemove: number | null = null
      const addToast = !suppressToasts ? enqueueToast : () => {}

      if (isInWishList(id)) {
        idToRemove = id
      } else if (sizeId && isInWishList(sizeId)) {
        idToRemove = sizeId
      }

      if (idToRemove) {
        try {
          const wishListId = getWishListItem(idToRemove)?.wishId
          await sendApiData({
            endpoint: `wishlist/${wishListId}/`,
            method: `DELETE`,
            body: {}
          })
          await mutateApiData(`wishlist/slim`)
          addToast(`Item was removed from your wishlist`, {
            variant: `wishlistAdd`,
            subtext: `${totalWishListItems - 1} items`,
            testId: `wishlist-toast-content`
          })

          return { success: true }
        } catch {
          addToast(`Sorry, something went wrong.`, {
            testId: `wishlist-toast-content`
          })

          return { success: false }
        }
      }
      addToast(`Sorry, something went wrong.`, {
        testId: `wishlist-toast-content`
      })

      return { success: false }
    },
    [enqueueToast, totalWishListItems, isInWishList, getWishListItem]
  )

  // If the user is logged out when they try to add a product to their wishlist, store the product ID in state and then try to add it after they successfully log in
  useEffect(() => {
    if (isLoggedIn && idToAddAfterLogin && canUseDOM) {
      const localStorageIdToAdd =
        window?.localStorage?.getItem?.(`wishlistProductId`)
      const localStorageScrollPosition = window?.localStorage?.getItem?.(
        `wishlistScrollPosition`
      )

      if (localStorageIdToAdd && +localStorageIdToAdd === idToAddAfterLogin) {
        add(idToAddAfterLogin)
        setIdToAddAfterLogin(null)
        window?.localStorage?.removeItem?.(`wishlistProductId`)

        if (trackDYAddAfterLogin.current === true) {
          trackEngagement({
            type: `WISHLIST_ADD`,
            productId: idToAddAfterLogin
          })

          trackDYAddAfterLogin.current = false
        }

        if (localStorageScrollPosition) {
          window?.scrollTo?.(0, +localStorageScrollPosition)
          window?.localStorage?.removeItem?.(`wishlistScrollPosition`)
        }
      }
    }
  }, [idToAddAfterLogin, isLoggedIn, add, trackEngagement])

  // Set scroll position and product ID in local storage to ensure the values aren't lost when the user logs in
  useEffect(() => {
    if (canUseDOM && idToAddAfterLogin) {
      const currentScrollPosition = window?.sessionStorage?.getItem?.(
        `@@scroll|${location.pathname}|${location.key}`
      )

      window.localStorage.setItem(`wishlistProductId`, `${idToAddAfterLogin}`)

      if (currentScrollPosition) {
        window.localStorage.setItem(
          `wishlistScrollPosition`,
          currentScrollPosition
        )
      }
    }
  }, [idToAddAfterLogin, location.key, location.pathname])

  return {
    add,
    remove,
    isInWishList,
    totalWishListItems,
    getWishListItem,
    wishListSlim
  }
}

export default useWishListContextData
