import React from "react"
import PropTypes from "prop-types"
import { SnackbarProvider } from "notistack"
import BasicNotification from "components/Toast/BasicNotification"
import WishlistNotification from "components/Toast/WishlistNotification"
import AddressBookDeleteNotification from "components/Toast/AddressBookNotification"
import GreenCheckBasicNotification from "components/Toast/GreenCheckBasicNotification"
import CodeAppliedNotification from "components/Toast/CodeAppliedNotification"
import FollowBrandNotification from "components/Toast/FollowBrandNotification"
import UnfollowBrandNotification from "components/Toast/UnfollowBrandNotification"
import BasicNotificationWithSansFont from "components/Toast/BasicNotificationWithSansFont"
import ErrorBasicNotification from "components/Toast/ErrorBasicNotification"

const ToastProvider = ({ children }) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: `bottom`,
      horizontal: `right`
    }}
    Components={{
      default: BasicNotification,
      wishlistAdd: WishlistNotification,
      wishlistRemove: WishlistNotification,
      AddressBookRemove: AddressBookDeleteNotification,
      defaultWithGreenCheck: GreenCheckBasicNotification,
      errorSans: ErrorBasicNotification,
      codeApplied: CodeAppliedNotification,
      followBrand: FollowBrandNotification,
      unfollowBrand: UnfollowBrandNotification,
      basicSans: BasicNotificationWithSansFont
    }}
  >
    {children}
  </SnackbarProvider>
)

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default ToastProvider
