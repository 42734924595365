import { createContext } from "react"

export interface OneTrustContextType {
  oneTrustAnalyticsApplied: boolean
  setOneTrustAnalyticsApplied: (value: boolean) => void
}

const OneTrustContext = createContext<OneTrustContextType>({
  oneTrustAnalyticsApplied: false,
  setOneTrustAnalyticsApplied: () => {}
})

export default OneTrustContext
