import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ToastNotification from "../ToastNotification"

const WishlistNotification = forwardRef(({ message, subtext, testId }, ref) => (
  <ToastNotification ref={ref} testId={testId}>
    <div className="flex items-center justify-between">
      <div>
        <div className="font-medium font-sans">{message}</div>
        {subtext && (
          <div className="text-gray-500 text-xs md:text-sm">{subtext}</div>
        )}
      </div>
      <div>
        <Link
          to="/wishlist"
          className="uppercase font-sans tracking-wider font-bold text-xs md:text-sm"
        >
          View
        </Link>
      </div>
    </div>
  </ToastNotification>
))

WishlistNotification.defaultProps = {
  subtext: undefined,
  testId: undefined
}

WishlistNotification.propTypes = {
  message: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  testId: PropTypes.string
}

export default WishlistNotification
