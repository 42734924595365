import { useSnackbar } from "notistack"

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar() ?? { enqueueSnackbar: undefined }

  const enqueueToast = (content, options = {}) => {
    enqueueSnackbar?.(content, {
      autoHideDuration: 3000,
      ...options
    })
  }

  return { enqueueToast }
}

export default useToast
