import React, { forwardRef } from "react"
import ToastNotification from "components/Toast/ToastNotification"
import { ErrorIcon } from "components/Icons"

interface ErrorBasicNotificationProps {
  message: string
  testId: string
}

const ErrorBasicNotification = forwardRef(
  ({ message, testId }: ErrorBasicNotificationProps, ref) => (
    <ToastNotification
      ref={ref}
      testId={testId}
      className="flex items-center gap-4 font-sans font-light"
    >
      <ErrorIcon width={28} height={28} />
      {message}
    </ToastNotification>
  )
)

export default ErrorBasicNotification
