import React, { forwardRef } from "react"

import ToastNotification from "components/Toast/ToastNotification"

interface FollowBrandNotificationProps {
  message: string
  testId?: string
}

const BasicNotificationWithSansFont = forwardRef(
  ({ message, testId }: FollowBrandNotificationProps, ref) => (
    <ToastNotification ref={ref} testId={testId}>
      <span className="font-sans leading-5">{message}</span>
    </ToastNotification>
  )
)

export default BasicNotificationWithSansFont
