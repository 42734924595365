// This file should be deprecated and new helpers added to the typescript file in the same directory. Once these existing helpers have been converted to typescript, this file can be deleted.
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import localizedFormat from "dayjs/plugin/localizedFormat"
import shajs from "sha.js"
import DOMPurify from "isomorphic-dompurify"
import { IMAGE_ASSETS_URL } from "lib/environment"

export const canUseDOM = !!(
  typeof window !== `undefined` &&
  window.document &&
  window.document.createElement
)

export const capitalize = string => string[0].toUpperCase() + string.slice(1)

export const truncate = (str, max, suffix) =>
  str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(` `)
      )}${suffix}`

export const letters = [
  `a`,
  `b`,
  `c`,
  `d`,
  `e`,
  `f`,
  `g`,
  `h`,
  `i`,
  `j`,
  `k`,
  `l`,
  `m`,
  `n`,
  `o`,
  `p`,
  `q`,
  `r`,
  `s`,
  `t`,
  `u`,
  `v`,
  `w`,
  `x`,
  `y`,
  `z`,
  `-`
]

export const isTouchDevice = () =>
  canUseDOM &&
  (`ontouchstart` in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0)

export const chunkArray = ({ array, numPerChunk }) =>
  array.reduce((all, one, i) => {
    const newAll = all
    const ch = Math.floor(i / numPerChunk)
    newAll[ch] = [].concat(all[ch] || [], one)
    return newAll
  }, [])

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const noNumberRegex = /^[^0-9]*$/

export const onlyNumbersRegex = /^[0-9]*$/

export const htmlToPlainText = text => text.replace(/<[^>]+>/g, ``)

export const titleCase = str =>
  str
    .toLowerCase()
    .split(` `)
    .map(word => word.replace(word[0], word[0].toUpperCase()))
    .join(` `)

export const convertToSlug = text =>
  text
    .toLowerCase()
    .replace(/ /g, `-`)
    .replace(/[^\w-]+/g, ``)

export const mapAlgoliaProducts = ({ products, legacySymbol, currencyCode }) =>
  products.map(item => ({
    ...item,
    id: item.id,
    uri: item.absolute_url,
    title: item.name,
    mainImage: {
      image: item.main_image_picture,
      altText: item.name
    },
    altImage: item.alt_image_picture,
    brand: {
      title: item.designer_name,
      uri: item.designer_url
    },
    onSale: item.algolia_on_sale ? item.algolia_on_sale : false,
    price:
      item.algolia_zoned_unit_prices[legacySymbol.toLowerCase()][currencyCode],
    salePrice:
      item.algolia_zoned_sale_prices[legacySymbol.toLowerCase()][currencyCode]
  }))

export const availabilityMessage = stockNumber => {
  let message = ``
  if (stockNumber <= 10) {
    message = `(Low Stock)`
  }
  if (stockNumber < 4) {
    message = `(Low Stock - Only ${stockNumber} left)`
  }
  if (stockNumber === 0) {
    message = `(Out of stock)`
  }
  return message
}

export const scrollToElement = ({ element, offset = 0 }) => {
  const elementTopPosition =
    element.getBoundingClientRect().top + window.pageYOffset - offset
  if (elementTopPosition > 0) {
    setTimeout(() => {
      window.scrollTo({ top: elementTopPosition, behavior: `instant` })
    }, 700)
  }
}

export const insertIntoArray = ({ array, index, newItem }) => [
  ...array.slice(0, index),
  newItem,
  ...array.slice(index)
]

export const removeUrLPrefix = (path, urlPrefix) => {
  if (path?.startsWith(`/${urlPrefix}`)) {
    return path.replace(`/${urlPrefix}`, ``)
  }
  return path
}

export const mapBagForTracking = ({ bag, urlPrefix }) => {
  if (bag) {
    const products = bag.subCarts.reduce(
      (prev, curr) => [
        ...prev,
        ...curr.cartItems.map(item => ({
          product_id: item.product.parentId,
          sku: null,
          name: item.product.name,
          price: item.product.price.total,
          position: null,
          category: null,
          quantity: item.quantity,
          coupon: bag.discountCode,
          brand: curr.finalDispatchFrom.name,
          variant: item.product.id,
          url: `${window.location.origin}/${urlPrefix}${item.product.uri}`,
          image_url: `${IMAGE_ASSETS_URL}/c_pad,h_800,w_800/${item.product.imageUrl}`
        }))
      ],
      []
    )

    return products
  }
  return []
}

export const userGreetMessage = ({ userName }) => {
  const date = new Date()
  const hours = date?.getHours()

  let greet

  if (hours < 12) {
    greet = `morning`
  } else if (hours >= 12 && hours <= 17) {
    greet = `afternoon`
  } else if (hours >= 17 && hours <= 24) {
    greet = `evening`
  }

  const message = `Good ${greet}${userName ? `, ${userName}` : ``}`

  return message
}

export const delay = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const languageCodes = {
  uk: `en-gb`,
  us: `en-us`,
  au: `en-au`,
  ca: `en-ca`,
  eu: `en-eu`,
  global: `en`
}

export const localiseDateFormat = (regionCode, date) => {
  dayjs.extend(localizedFormat)

  if (regionCode === `us`) {
    return dayjs(date).format(`L`)
  }
  return dayjs(date).format(`DD/MM/YYYY`)
}

const homepageTrackingGroups = {
  heroImages: `Hero images`,
  shopBy: `"Shop by" links`,
  shopByCollection: `Shop by collection`,
  meetTheDesigners: `"Meet our designers" links`,
  london: `Our Stores: London`,
  newyork: `Our Stores: New York`,
  losangeles: `Our Stores: Los Angeles`,
  magazine: `Magazine`,
  thanks: `"Thanks It's Independent": User Generated Content`,
  thanksUploadPhoto: `"Thanks It's Independent": Upload your photo CTA`,
  editorsPicksFavourites: `Editor's Picks: Favourites`,
  editorsPicksJustLanded: `Editor's Picks: Just Landed`
}

export const trackHomepageClick = ({ name, group, position = 1 }) => {
  if (!homepageTrackingGroups[group]) return
  if (!name) return

  window?.analytics?.track?.(`Promotion Clicked`, {
    promotion_id: `Homepage`,
    creative: homepageTrackingGroups[group],
    name,
    position
  })
}

export const trackHomepageView = ({
  name,
  group,
  properties = undefined,
  position = 1
}) => {
  if (!homepageTrackingGroups[group]) return
  if (!name) return

  window?.analytics?.track?.(`Promotion Viewed`, {
    promotion_id: `Homepage`,
    creative: homepageTrackingGroups[group],
    name,
    position,
    ...properties
  })
}

export const mapContentfulColour = colour => {
  const colours = {
    black: `black`,
    beige: `#E8E0CC`,
    grey: `#EBEBEB`
  }

  const mappedColour = colours[colour.toLowerCase()] || `black`

  return mappedColour
}

export const identifyWithTrackers = ({ email, id }) => {
  if (id) {
    window?.analytics?.identify?.(id, {
      email,
      hashedEmail: shajs(`sha256`).update(email).digest(`hex`)
    })
    window?.dataLayer?.push?.({
      hashedEmail: shajs(`sha256`).update(email).digest(`hex`)
    })
  }
  window?.ometria?.identify?.(email)
}

export const getRecentOrder = orders => {
  dayjs.extend(customParseFormat)
  const expiredDate = dayjs().subtract(30, `day`).format(`YYYY/MM/DD`)
  const invalidStatusValues = [
    `return started`,
    `return in transit`,
    `return received`,
    `return cancelled`,
    `returned`,
    `order cancelled`,
    `declined`,
    `refunded`
  ]

  const recentOrder = orders?.find(order => {
    const filteredOrder = order
    const { orderDate } = order
    const date = dayjs(orderDate, `DD-MM-YYYY`).format(`YYYY/MM/DD`)
    const subOrders = order.suborders.filter(
      subOrder =>
        dayjs(date).isAfter(expiredDate) &&
        !invalidStatusValues.includes(subOrder.status.toLowerCase())
    )
    if (subOrders.length > 0) {
      filteredOrder.suborders = subOrders
      return filteredOrder
    }
    return false
  })
  return recentOrder
}

export const getStatusBgColour = status => {
  const statuses = [
    {
      status: [`order placed`, `accepted`, `processing`, `shipped`],
      color: `bg-status-blue`
    },
    {
      status: [
        `return started`,
        `return in transit`,
        `return received`,
        `return cancelled`,
        `returned`,
        `order cancelled`
      ],
      color: `bg-light-gray`
    },
    { status: [`delivered`], color: `bg-status-green` },
    { status: [`refunded`], color: `bg-black text-white` },
    { status: [`ready to collect`], color: `bg-status-green` },
    { status: [`order collected`], color: `bg-status-green` }
  ]

  const filteredStatus = statuses.filter(item =>
    item.status.includes(status.toLowerCase())
  )

  const bgColour = filteredStatus?.[0]?.color

  return bgColour || ``
}

export const sanitiseObject = obj => {
  if (typeof obj !== `object` || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(item => sanitiseObject(item))
  }

  const newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === `string`) {
      newObj[key] = DOMPurify.sanitize(value)
    } else {
      newObj[key] = sanitiseObject(value)
    }
  }

  return newObj
}
