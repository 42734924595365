import { useState } from "react"

import { mutateApiData, sendApiData } from "hooks/useApiData"
import useToast from "hooks/useToast"

const useFollowingBrands = ({
  id,
  brandPage
}: {
  id: number
  brandPage?: number
}) => {
  const [isRequestingFollow, setIsRequestingFollow] = useState(false)
  const [isRequestingUnfollow, setIsRequestingUnfollow] = useState(false)
  const { enqueueToast } = useToast()

  const follow = async () => {
    setIsRequestingFollow(true)

    try {
      const res = await sendApiData({
        endpoint: `user/following-brands/${id}/follow`,
        method: `POST`,
        body: {}
      })

      if (res?.ok) {
        enqueueToast(``, {
          variant: `followBrand`
        })

        await mutateApiData(`user/following-brands/${id}`)
        if (typeof brandPage === `number` && brandPage > 0) {
          mutateApiData(`user/following-brands/?page=${brandPage}`, {
            revalidate: true
          })
        }
        setIsRequestingFollow(false)

        window?.analytics?.track?.(`Brand Followed`, {
          brand_id: id
        })
      } else {
        enqueueToast(`Sorry, something went wrong. Please try again.`, {
          variant: `basicSans`
        })
        setIsRequestingFollow(false)
      }
    } catch {
      enqueueToast(`Sorry, something went wrong. Please try again.`, {
        variant: `basicSans`
      })
    }
  }

  const unfollow = async () => {
    setIsRequestingUnfollow(true)

    try {
      const res = await sendApiData({
        endpoint: `user/following-brands/${id}/unfollow`,
        method: `DELETE`,
        body: {}
      })

      if (res?.ok) {
        enqueueToast(``, { variant: `unfollowBrand`, id })

        if (typeof brandPage === `number` && brandPage > 0) {
          await Promise.all([
            mutateApiData(`user/following-brands/${id}`),
            mutateApiData(`user/following-brands/?page=${brandPage}`)
          ])
        } else {
          await mutateApiData(`user/following-brands/${id}`)
        }

        setIsRequestingUnfollow(false)

        window?.analytics?.track?.(`Brand Unfollowed`, {
          brand_id: id
        })
      } else {
        enqueueToast(`Sorry, something went wrong. Please try again.`, {
          variant: `basicSans`
        })
        setIsRequestingUnfollow(false)
      }
    } catch {
      enqueueToast(`Sorry, something went wrong. Please try again.`, {
        variant: `basicSans`
      })
    }
  }

  return { follow, isRequestingFollow, unfollow, isRequestingUnfollow }
}

export default useFollowingBrands
