import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import ToastNotification from "components/Toast/ToastNotification"
import { GreenCheckIcon } from "components/Icons"

const CodeAppliedNotification = forwardRef(({ message, testId }, ref) => (
  <ToastNotification
    ref={ref}
    testId={testId}
    className="flex items-center gap-4 font-sans font-light"
  >
    <GreenCheckIcon width={28} height={28} />
    {message}
  </ToastNotification>
))

CodeAppliedNotification.defaultProps = {
  testId: undefined
}

CodeAppliedNotification.propTypes = {
  message: PropTypes.string.isRequired,
  testId: PropTypes.string
}

export default CodeAppliedNotification
