import useSWR, { mutate } from "swr"
import type { SWRConfiguration } from "swr"

interface SendApiData {
  endpoint: string
  method: string
  body?: Record<string, unknown>
  signal?: AbortSignal
}

interface FetchApiDataOptions extends SWRConfiguration {
  method?: string
}

export const fetchApiData = <T>(
  endpoint: string,
  options: FetchApiDataOptions = {}
) => {
  const fetcher = async (url: string) => {
    const res = await fetch(url, {
      method: options?.method || `GET`
    })
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new Error(
        `An error occurred while fetching the data.`
      ) as Error & {
        info: Record<string, unknown>
        status: number
      }
      // Attach extra info to the error object.
      error.info = await res.json()
      error.status = res.status
      throw error
    }
    return res.json()
  }

  let url = null

  if (endpoint === null || endpoint.startsWith(`http`)) {
    url = endpoint
  } else {
    url = `/api/v1/${endpoint}`
  }

  // Disabling because the linter is getting confused
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSWR<T>(url, {
    fetcher,
    revalidateOnReconnect: true,
    refreshInterval: 0, // Do not poll
    shouldRetryOnError: true,
    dedupingInterval: 30000, // Fetch at most once every 30 seconds
    revalidateOnFocus: false,
    focusThrottleInterval: 30000,
    ...options
  })
}

export const mutateApiData = (endpoint: string) => mutate(`/api/v1/${endpoint}`)

export const sendApiData = async ({
  endpoint,
  method,
  body = undefined,
  signal = undefined
}: SendApiData) => {
  const isCSRFRequired = method !== `GET`

  let session = null
  if (isCSRFRequired) {
    session = await (await fetch(`/api/v1/session/csrf`)).json()
  }

  return new Promise((resolve, reject) => {
    fetch(`/api/v1/${endpoint}`, {
      method,
      mode: `same-origin`,
      headers: {
        "Content-Type": `application/json`,
        ...(session ? { "X-CSRFToken": session.csrf } : {})
      },
      ...(body && { body: JSON.stringify(body) }),
      ...(signal && { signal })
    })
      .then(res => {
        if (res.ok) resolve(res)
        reject(res)
      })
      .catch(() => {
        // eslint-disable-next-line no-console
      })
  })
}
