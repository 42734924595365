const canUseDOM = !!(
  typeof window !== `undefined` &&
  window.document &&
  window.document.createElement
)

const LOCAL_DOMAIN = `localhost`
const STAGING_DOMAIN = `staging.wolfandbadger.com`
const PRODUCTION_DOMAIN = `www.wolfandbadger.com`
const SUPPORT_DOMAIN = `https://support.wolfandbadger.com/`
const CUSTOMER_SUPPORT_FORM = `https://contact.wolfandbadger.com/customer`

const generateArticlePath = id =>
  `https://support.wolfandbadger.com/en/article/${id}`

const WHERE_IS_MY_ORDER_ARTICLE = generateArticlePath(`a9fcef`)
const CANCEL_MY_ORDER_ARTICLE = generateArticlePath(`53c7b2`)
const WHERE_IS_MY_REFUND_ARTICLE = generateArticlePath(`e4be0c`)
const HOW_DO_I_RETURN_MY_ORDER_ARTICLE = generateArticlePath(`04655b`)

// eslint-disable-next-line no-unused-vars
const SEGMENT_CONSENT = `segment`
const ONETRUST_CONSENT = `onetrust`

const getCurrentDomain = () =>
  (canUseDOM && window.location.hostname) || `www.wolfandbadger.com`

const segmentRegionKeys = {
  uk: `G2XvqjxngEoEGE1TCg0jgL6v04AKNUuE`,
  us: `MF0T2XQCFT79K0bGMPZIrfx07EEoirFC`,
  au: `xKbTQKFNV0NTUetEC5AX9Wc4pIIirAdR`,
  ca: `3sivNNsP12cj6oIHwOcRDMDla0PLBLiu`,
  eu: `rMWcHJSl5Xe1CdrG3kF7Tc5oKAbeYHVB`,
  global: `fJuf7NXHQBFUUeZEN4KnMOqkYLbSEFm3`
}

module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  CURRENT_REGION: process.env.GATSBY_REGION,
  CLIENT_CONTENTFUL_ACCESS_TOKEN: `vByXlB79joxayfQufnTVJOZlcUg4qyUT2QASlvLLEh8`,
  IMAGE_ASSETS_URL: `https://res.cloudinary.com/wolfandbadger/image/upload`,
  ALGOLIA_APP_ID: `XO4U4Y7C0T`,
  ALGOLIA_SEARCH_KEY: `bb6097513c7231e0779e90db875b7563`,
  GIFT_UP_SITE_ID: {
    uk: `81432c28-1887-4809-9a38-08dabdb611bd`,
    us: `07ff126b-577b-4220-5393-08dac62de78f`,
    au: `016702af-9895-481a-5592-08db8919cf14`,
    ca: undefined,
    eu: undefined,
    global: undefined
  }[process.env.GATSBY_REGION],
  SEGMENT_ID: process.env.GATSBY_SEGMENT_ID
    ? process.env.GATSBY_SEGMENT_ID
    : segmentRegionKeys[process.env.GATSBY_REGION],
  DYNAMIC_YIELD_STAGING_SECTION_ID: `9880767`,
  DYNAMIC_YIELD_PRODUCTION_SECTION_ID: `9880861`,
  getCurrentEnv: () => {
    const environments = {
      [LOCAL_DOMAIN]: `local`,
      [STAGING_DOMAIN]: `staging`,
      [PRODUCTION_DOMAIN]: `production`
    }
    return (
      (canUseDOM && environments[getCurrentDomain()]) ||
      environments[PRODUCTION_DOMAIN]
    )
  },
  isLocal: () => getCurrentDomain() === LOCAL_DOMAIN,
  isStaging: () => getCurrentDomain() === STAGING_DOMAIN,
  isProduction: () => getCurrentDomain() === PRODUCTION_DOMAIN,
  isPreviewDeck: () =>
    canUseDOM &&
    window.location.hostname.match(/preview-deck-(.*?).wolfandbadger.com/i) !==
      null,
  sentryDsn: `https://881fa3da5e004cf9923f23e59bbffc82@o4412.ingest.sentry.io/5525219`,
  CONTENTFUL_ACCESS_TOKEN: `6QSEhlhrxuAYfgLIMpHFUDQLcJqpY9KvsasRY_4CVBw`,
  CONTENTFUL_SPACE_ID: `wrfbz9rvi8t0`,
  CONTENTFUL_ENVIRONMENT: `master`,
  RELEASE_NAME: process.env.GATSBY_RELEASE_NAME,
  CONSENT_MANAGEMENT_PLATFORM: {
    uk: ONETRUST_CONSENT,
    us: ONETRUST_CONSENT,
    au: ONETRUST_CONSENT,
    ca: ONETRUST_CONSENT,
    eu: ONETRUST_CONSENT,
    global: ONETRUST_CONSENT
  }[process.env.GATSBY_REGION],
  ALGOLIA_SEARCH_KEYS: {
    algoliaSearchKey: `bb6097513c7231e0779e90db875b7563`,
    rateLimitedSearchKey: `b74cf1c04255441f0a735f5275087403`
  },
  SUPPORT_DOMAIN,
  CUSTOMER_SUPPORT_FORM,
  WHERE_IS_MY_ORDER_ARTICLE,
  CANCEL_MY_ORDER_ARTICLE,
  WHERE_IS_MY_REFUND_ARTICLE,
  HOW_DO_I_RETURN_MY_ORDER_ARTICLE
}
