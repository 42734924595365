import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import ToastNotification from "../ToastNotification"

const AddressBookDeleteNotification = forwardRef(
  ({ message, subtext, testId }, ref) => (
    <ToastNotification ref={ref} testId={testId}>
      <div className="flex items-center justify-between">
        <div>
          <div className="font-medium font-sans">{message}</div>
          {subtext && (
            <div className="text-gray-500 text-xs md:text-sm">{subtext}</div>
          )}
        </div>
      </div>
    </ToastNotification>
  )
)

AddressBookDeleteNotification.defaultProps = {
  subtext: undefined,
  testId: undefined
}

AddressBookDeleteNotification.propTypes = {
  message: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  testId: PropTypes.string
}

export default AddressBookDeleteNotification
