import React, { forwardRef } from "react"

import ToastNotification from "components/Toast/ToastNotification"
import { GreenCheckIcon } from "components/Icons"
import UndoButton from "components/Toast/UnfollowBrandNotification/UndoButton"

interface UnfollowBrandNotificationProps {
  id: number
  testId?: string
}

const UnfollowBrandNotification = forwardRef(
  ({ testId, id }: UnfollowBrandNotificationProps, ref) => (
    <ToastNotification ref={ref} testId={testId}>
      <div className="flex items-center gap-3">
        <GreenCheckIcon width={22} height={22} className="shrink-0" />
        <div className="grow-0 font-sans leading-5">
          Removed from your followed brands.{` `}
          <UndoButton id={id} />
        </div>
      </div>
    </ToastNotification>
  )
)

export default UnfollowBrandNotification
