import React, { forwardRef } from "react"
import { Link } from "gatsby"

import ToastNotification from "components/Toast/ToastNotification"
import { GreenCheckIcon } from "components/Icons"

interface FollowBrandNotificationProps {
  testId?: string
}

const FollowBrandNotification = forwardRef(
  ({ testId }: FollowBrandNotificationProps, ref) => (
    <ToastNotification ref={ref} testId={testId}>
      <div className="flex items-center gap-3">
        <GreenCheckIcon width={22} height={22} className="shrink-0" />
        <div className="grow-0 font-sans leading-5">
          <span className="font-sans">
            Following! Discover more brands or edit your list in your account.
          </span>
          {` `}
          <Link
            to="/my-account/followed-brands/"
            className="font-medium underline"
          >
            View Followed Brands
          </Link>
        </div>
      </div>
    </ToastNotification>
  )
)

export default FollowBrandNotification
